import axios from "axios"
import configs from "./api"
import utils from "@/common/utils"
import Vue from "vue"
import { Toast } from 'vant';
import _ from 'lodash';
// axios.defaults.baseURL = process.env.VUE_APP_URL
function request(reqObj, data) {
  //该次请求的取消源
  let cancelSource = undefined;
  let vueIns = undefined;//请求的vue实例，如果不是在vue实例内的请求则没有

  /*
    vue组件内调用时会在期生命周期结束时自动清理副作用
    因此，如果是跨生命周期的异步请求，请勿使用实例上的 $request 方法。
  */

  let isVue = this instanceof Vue;

  if (isVue) {
    cancelSource = axios.CancelToken.source();
    vueIns = this;
    vueIns.CancelTokens.push(cancelSource);
  }

  let Obj = {}
  let reqObjClone = _.cloneDeep(reqObj)
  if (typeof reqObj == "string") {
    //读取配置
    reqObjClone = _.cloneDeep(configs[reqObj])
  }

  Object.assign(Obj, reqObjClone);

  //normalize
  Obj.data = Obj.data || {};
  Obj.method = Obj.method || "post"
  const showToast = _.get(Obj, 'data.showToast', true)
  const showLoading = _.get(Obj, 'data.showLoading', true)
  delete Obj.data.showToast
  delete Obj.data.showToast

  //抄写伴随Key的参数
  Object.assign(Obj.data, data)

  //处理pathString
  if (Obj.pathField) {
    Obj.url = Obj.url.replace(`{${Obj.pathField}}`, Obj.data[Obj.pathField])
    Reflect.deleteProperty(Obj.data, Obj.pathField) //防止被拼接在get请求后面
  }

  // 取消令牌
  if (isVue) {
    Obj.cancelToken = cancelSource.token;
  }

  let resultHeaders = {}

  if (Obj.method == "get") {
    Obj.params = Obj.data;
    utils.paramsNormalize(Obj.params);
  }

  if (Obj.headers) {
    resultHeaders = Object.assign(resultHeaders, Obj.headers)
  }

  if (Obj.method == "post") {
    Obj.type = Obj.type || 'json';//默认json
  }

  Obj.headers = resultHeaders;
  // https://www.baidu.com/s?ie=UTF-8&wd=gwaaah   I_PARK_DOMAIN=gwaaah
  Obj.headers['I_PARK_DOMAIN']= this.$route.params.id

  showLoading && Toast.loading({
    duration: 0, // 持续展示 toast
    forbidClick: true,
    message: '加载中',
  })

  return new Promise((resolve, reject) => {
    axios(Obj).then((res) => {

      //清理cancelToken
      if (isVue) {
        _.remove(vueIns.CancelTokens, cancelSource);
      }

      showLoading && Toast.clear();

      if (res.data.code == "200") {
        //Toast()
        resolve(res.data);
        return;
      }
      if (res.data.code == "404" || res.data.code == "15" || res.data.code == "99") {
        return reject(res)
      }
      showToast && Toast.fail({
        message: res.data.msg
      })

      reject(res)

    }, (res) => {
      console.log("error", res)
      _.get(res, 'data.msg') && Toast.fail({
        message: res.data.msg
      })
      if (isVue) {
        _.remove(vueIns.CancelTokens, cancelSource);
      }

      showLoading && Toast.clear();
      reject(res)
    }).catch(thrown => {

      if (axios.isCancel(thrown)) {
        console.warn('Request canceled', thrown.message);
        resolve("")
      } else {
        console.log(thrown)
        reject(thrown)
      }

    })
  })
}


export default request