/*
 * @Author: yejincheng
 * @Date: 2020-12-12 16:20:17
 * @LastEditors: WangHan
 * @LastEditTime: 2020-12-18 12:46:46
 * @Description: 
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '@/pages/index';
import InvestmentInfo from '@/pages/investmentInfo/index';
import InvestmentPolicy from '@/pages/investmentPolicy';
import ParkDynamics from '@/pages/parkDynamics';
import InfoDetail from '@/pages/investmentInfo/infoDetail';
import ParkDetail from '@/pages/index/parkDetail';
import ParkDynamicsDetail from '@/pages/parkDynamics/parkDynamicsDetail';
import PolicyDetail from '@/pages/investmentPolicy/policyDetail'
Vue.use(VueRouter);

const routes = [
  {
    path: '/:id',
    name: 'Index',
    component: Index,
  },
  // 园区概况
  {
    path: '/index/parkDetail/:id',
    name: 'ParkDetail',
    component: ParkDetail,
  },
  {
    // 招商信息
    path: '/investmentInfo/:id',
    name: 'InvestmentInfo',
    component: InvestmentInfo,
    meta: {
      keepAlive: true,
      scrollTop: [0, 0, 0]
    }
  },
  // 招商详情
  {
    path: '/investmentInfo/infoDetail/:id',
    name: 'InfoDetail',
    component: InfoDetail,
  },
  {
    // 园区动态
    path: '/parkDynamics/:id',
    name: 'ParkDynamics',
    component: ParkDynamics,
  },
  // 园区动态详情
  {
    path: '/parkDynamics/parkDynamicsDetail/:id',
    name: 'ParkDynamicsDetail',
    component: ParkDynamicsDetail,
  },
  {
    // 招商政策
    path: '/investmentPolicy/:id',
    name: 'InvestmentPolicy',
    component: InvestmentPolicy,
  },
  // 招商政策详情
  {
    path: '/investmentPolicy/policyDetail/:id',
    name: 'PolicyDetail',
    component: PolicyDetail,
  },
  {
    path: '**',
    component: Index,
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});
router.beforeEach((to, from, next) => {  
  if (from.meta.keepAlive) {
    const $content = document.querySelectorAll('.van-pull-refresh'); // 列表的外层容器
    for(let i = 0; i < $content.length; i++) {
      from.meta.scrollTop[i] = $content[i] ? $content[i].scrollTop : 0
    }
    
  }
  next();
});
export default router;
