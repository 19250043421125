<!--
 * @Author: yejincheng
 * @Date: 2020-12-16 10:46:23
 * @LastEditors: huangxingli
 * @LastEditTime: 2020-12-16 15:10:57
 * @Description: 
-->
<template>
  <div>
    <Heard :title="'招商政策'" :needBack="true" />
    <section class="content" v-if="!noData && !error">
      <div class="content-title">{{ detailData.title }}</div>
      <span class="content-date">{{ detailData.updateDate }}</span>
      <div class="content-desc" v-html="stringToTag(detailData.content)"></div>
    </section>
    <NoData v-else :error="error" :text="'该内容已下架，暂时无法浏览'" />
  </div>
</template>
<script>
import Heard from '@/components/Heard';
import utils from '@/common/utils';
import NoData from '@/components/NoData';
export default {
  name: 'PolicyDetai',
  components: {
    Heard,
    NoData,
  },
  data() {
    return {
      detailData: {},
      noData: false,
      error:false
    };
  },
  created() {
    this.$request({
      method: 'get',
      url: `/ipark-website/h5/policy/detail/${this.$route.query.id}`,
    })
      .then((res) => {
        this.detailData = { ...res.data };
      })
      .catch((err) => {
        if([15,99,404].includes(err.data.code)) {
          this.noData = true
        }else{
          this.error=true
        }
      });
  },
  methods: {
    stringToTag: utils.stringToTag,
  },
};
</script>
<style lang="less" scoped>
.content {
  padding: 0px 15px;
  margin-top: 62px;
  &-title {
    font-size: 27px;
    font-weight: 700;
    margin-bottom: 12px;
    word-break: break-all;
  }
  &-date {
    font-size: 15px;
    color: #999999;
  }
  &-desc {
    font-size: 18px;
    line-height: 32px;
    color: #333333;
    margin: 16px 0;
    letter-spacing: 0;
    word-break: break-all;
  }
}
</style>
