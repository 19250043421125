<template>
  <van-swipe @change="onChange" class="swipe-box" :autoplay="3000">
    <van-swipe-item
      @click="previewImg(index)"
      v-for="(item,index) in photoList"
      :key="index"
      :style="{ 'background-image': `url(${item})` }"
    />
    <template #indicator>
      <div class="custom-indicator">
        {{ current + 1 }}/{{ photoList.length }}
      </div>
    </template>
  </van-swipe>
</template>

<script>
import { ImagePreview } from 'vant';
export default {
  name: 'SwiperImg',
  props: {
    photoList: {
      type: Array,
      default:()=>[],
    },
  },
  data() {
    return {
      current: 0,
    };
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
    previewImg(index) {
      ImagePreview({
        images:this.photoList,
        startPosition: index,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.custom-indicator {
  width: 43px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  line-height: 20px;
  position: absolute;
  right: 20px;
  bottom: 8px;
  font-size: 12px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
}
.van-swipe-item {
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.swipe-box {
  height: 211px;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.van-image-preview__overlay{
  background-color: #454955;
}
</style>
