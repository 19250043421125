<template>
  <div>
    <Heard :title="houseType + '详情'" :needBack="true" />
    <div v-if="!noData && !error" class="house-detail">
      <div>
        <SwiperImg :photoList="get(detailData, 'photos', [])" />
        <div class="house-info-box">
          <h4 class="title">{{ detailData.title }}</h4>
          <div class="info-one">
            <span class="price">{{ detailData.priceText }}</span>
            <span class="eare">{{ detailData.houseAreaText }}</span>
          </div>
          <!-- 厂房 -->
          <div class="house-info-two" v-if="houseType === '厂房'">
            <van-row class="item">
              <van-col span="12">
                <span class="lable">租售类型</span>
                <span class="value">{{
                  rentTypeList[detailData.rentType - 1].name
                }}</span>
              </van-col>
              <van-col span="12">
                <span class="lable">厂房类型</span>
                <span class="value">{{ detailData.plantTypeText }}</span>
              </van-col>
            </van-row>
            <van-row class="item">
              <van-col span="12">
                <span class="lable">厂房楼层</span>
                <span class="value">{{
                  get(detailData, 'plantFloorText', '暂无数据')
                }}</span>
              </van-col>
              <van-col span="12">
                <span class="lable">厂房结构</span>
                <span class="value">{{
                  get(detailData, 'plantStructText', '暂无数据')
                }}</span>
              </van-col>
            </van-row>
            <van-row class="item">
              <van-col span="12">
                <span class="lable">新旧程度</span>
                <span class="value">{{
                  get(detailData, 'plantQualityText', '暂无数据')
                }}</span>
              </van-col>
            </van-row>
          </div>
          <!-- 仓库 -->
          <div class="house-info-two" v-if="houseType === '仓库'">
            <van-row class="item">
              <van-col span="12">
                <span class="lable">租售类型</span>
                <span class="value">{{
                  rentTypeList[detailData.rentType - 1].name
                }}</span>
              </van-col>
              <van-col span="12">
                <span class="lable">仓库类型</span>
                <span class="value">{{ detailData.storeTypeText }}</span>
              </van-col>
            </van-row>
            <van-row class="item">
              <van-col span="12">
                <span class="lable">底层层高</span>
                <span class="value">{{
                  get(detailData, 'storeBaseHighText', '暂无数据')
                }}</span>
              </van-col>
              <van-col span="12">
                <span class="lable">仓库结构</span>
                <span class="value">{{
                  get(detailData, 'storeStructText', '暂无数据')
                }}</span>
              </van-col>
            </van-row>
            <van-row class="item">
              <van-col span="12">
                <span class="lable">地坪功能</span>
                <span class="value">{{
                  get(detailData, 'storeFunctionText', '暂无数据')
                }}</span>
              </van-col>
              <van-col span="12">
                <span class="lable">地坪材质</span>
                <span class="value">{{
                  get(detailData, 'storeTextureText', '暂无数据')
                }}</span>
              </van-col>
            </van-row>
          </div>
          <!-- 写字楼 -->
          <div class="house-info-two" v-if="houseType === '写字楼'">
            <van-row class="item">
              <van-col span="12">
                <span class="lable">租售类型</span>
                <span class="value">{{
                  rentTypeList[detailData.rentType - 1].name
                }}</span>
              </van-col>
              <van-col span="12">
                <span class="lable">装修程度</span>
                <span class="value">{{
                  get(detailData, 'officeQualityText', '暂无数据')
                }}</span>
              </van-col>
            </van-row>
            <van-row class="item">
              <van-col span="12">
                <span class="lable">户型朝向</span>
                <span class="value">{{
                  get(detailData, 'officeOrientationText', '暂无数据')
                }}</span>
              </van-col>
              <van-col span="12">
                <span class="lable">工位数量</span>
                <span class="value">{{
                  get(detailData, 'officeStationText', '暂无数据')
                }}</span>
              </van-col>
            </van-row>
            <van-row class="item">
              <van-col span="12">
                <span class="lable">楼层信息</span>
                <span class="value">{{
                  get(detailData, 'officeFloorInfo', '暂无数据')
                }}</span>
              </van-col>
              <van-col span="12">
                <span class="lable">物业费</span>
                <span class="value">{{
                  get(detailData, 'officePropertyCost', '暂无数据')
                }}</span>
              </van-col>
            </van-row>
            <van-row class="item">
              <van-col span="12">
                <span class="lable">空调费</span>
                <span class="value">{{
                  get(detailData, 'officeAirCost', '暂无数据')
                }}</span>
              </van-col>
              <van-col span="12">
                <span class="lable">停车位</span>
                <span class="value">{{
                  get(detailData, 'officeParkCost', '暂无数据')
                }}</span>
              </van-col>
            </van-row>
          </div>
          <!-- 房源描述 -->
          <div class="house-des item">
            <div class="lable">房源描述</div>
            <div class="value">
              <div v-if="get(detailData, 'description', '').length > 30">
                <div class="pre-text" v-if="!showAll">
                  {{ detailData.description.slice(0, 30) }}...
                </div>
                <div class="pre-text" v-else>
                  {{ detailData.description }}
                </div>
                <span
                  class="icon-up"
                  type="link"
                  @click="
                    () => {
                      showAll = !showAll;
                    }
                  "
                  :class="!showAll ?  'up' :'down'"
                >
                </span>
              </div>
              <div class="dp-content" v-else>
                {{ get(detailData, 'description', '暂无数据') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SupportingTag
        v-if="detailData.labels"
        :values="get(detailData, 'labels', '')"
        class="tag-box"
      ></SupportingTag>
      <Amap
        v-if="detailData.address"
        :marker="[detailData.longitude, detailData.latitude]"
        class="adderss-box"
        :address="get(detailData,'province') + get(detailData,'city') + get(detailData,'area') + get(detailData,'town') + detailData.address"
      ></Amap>
      <Copyright class="footer"></Copyright>
      <div class="tel-box">
      <a class="tel" :href="'tel:'+ detailData.contractPhone ">
        <img src="../../../static/image/ic_phone.png" alt="" />
        <span>招商热线：{{ detailData.contractPhone }}</span>
      </a></div>
    </div>
    <NoData v-else :error="error" :text="'该内容已下架，暂时无法浏览'" />
  </div>
</template>

<script>
import Heard from '@/components/Heard';
import SwiperImg from '@/components/SwiperImg';
import utils from '@/common/utils';
import { get } from 'lodash';
import Copyright from '@/components/Copyright';
import SupportingTag from '@/components/SupportingTag';
import Amap from '@/components/Amap';
import NoData from '@/components/NoData';
const errCode = [15, 99,404]
export default {
  name: 'InfoDetail',
  components: {
    SwiperImg,
    Heard,
    Amap,
    Copyright,
    SupportingTag,
    NoData,
  },
  data() {
    return {
      isOpen: true,
      showAll: false,
      houseType: '',
      rentTypeList: [
        { id: 1, name: '出租' },
        { id: 2, name: '出售' },
      ],
      houseTypeList: [
        { id: 1, name: '厂房' },
        { id: 2, name: '仓库' },
        { id: 3, name: '写字楼' },
      ],
      detailData: {
        rentType: 1,
        address: undefined,
      },
  
      noData: false,
      error: false,
    };
  },
  created() {
    const { id, houseType = 1 } = this.$route.query;
    this.houseType = this.houseTypeList[houseType - 1].name;
    this.getHouseDetail(id);
  },
  methods: {
    get,
    getPhotoList: utils.getPhotoList,
    openContent() {
      this.showAll = !this.data.showAll;
    },
    getHouseDetail(id) {
      this.$request({
        method: 'get',
        url: `/ipark-website/h5/info/detail/${id}`,
      })
        .then((res) => {
          this.detailData = { ...res.data };
        })
        .catch((err) => {
          if (errCode.includes(err.data.code)) {
            this.noData = true;
          } else {
            this.error = true;
          }
        });
    },
   
  },
};
</script>

<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}
.house-detail {
  background: #f8f8f8;
  margin-top: 44px;
  padding-bottom: 58px;
}
.tag-box {
  background: #fff;
  padding: 18px 0;
  margin-bottom: 15px;
}
.adderss-box {
  padding: 18px 0;
  background: #fff;
}
.house-info-box {
  background: #fff;
  padding: 18px 15px;
  margin-bottom: 15px;
  .title {
    font-weight: bold;
    font-size: 21px;
    color: #333333;
    text-align: left;
    margin-bottom: 4px;
    word-break: break-all;
  }
  .info-one {
    margin-bottom: 13px;
    .price {
      color: #ff3b30;
      font-size: 15px;
      font-weight: bold;
      margin-right: 11px;
      line-height: 21px;
    }
    .eare {
      color: #999999;
      font-size: 13px;
      line-height: 18px;
    }
  }
  .item {
    font-size: 15px;
    color: #999;
    line-height: 21px;
    margin-bottom: 8px;
    & > .van-col {
      position: relative;
      padding-left: 70px;
    }
    .lable {
      width: 62px;
      font-size: 15px;
      display: block;
      text-align: right;
      position: absolute;
      left: 0;
    }
    .value {
      color: #333333;
      word-break: break-word;
    }
  }
  .house-des {
    position: relative;
    .lable {
      position: absolute;
      left: 0;
      top: 0;
    }
    .value {
      padding-left: 70px;
      width: auto;
      position: relative;
      word-break: break-all;
      .icon-up {
        position: absolute;
        bottom: 1px;
        right: 2px;
        height: 14px;
        width: 14px;
      }
      .up {
        background: url('../../../static/image/ic_down.png') no-repeat center /
          100%;
      }
      .down {
        background: url('../../../static/image/ic_up.png') no-repeat center /
          100%;
      }
    }
  }
}
.tel-box{
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0;
}
.tel {
  display: block;
  padding: 10px 20px;
  margin: 8px 15px;
  text-align: center;
  color: #fff;
  background: #1677ff;
  border-radius: 3px;
  word-break: break-all;
  img {
    height: 20px;
    width: 20px;
    background: #fff;
    border-radius: 50%;
    margin-right: 11px;
    vertical-align: sub;
  }
}
</style>
