<template>
  <div class="heard">
    <van-nav-bar
      :title="title"
      @click-left="onClickLeft"
      @click-right="onClickRight"
      :class="needBack ? '' : 'left-title'"
    >
      <template #left v-if="needBack">
        <van-icon name="arrow-left" size="24" color="#333" />
      </template>
      <template #right>
				<img class="right-img" src="../../static/image/park_ic_navigation.png" alt="">
      </template>
    </van-nav-bar>
    <van-popup v-model="showPopup" position="right">
      <div class="colse-icon" @click="closePopup">
        <van-icon name="cross" size="26" color="rgba(255, 255, 255, 0.6)" />
      </div>
      <div class="link">
        <router-link :to="`/${webName}`" :class="['Index','ParkDetail'].includes(pathName) ? 'active' :''">首页</router-link>
        <router-link :to="`/investmentInfo/${webName}`" :class="['InvestmentInfo','InfoDetail'].includes(pathName) ? 'active' :''">招商信息</router-link>
        <router-link :to="`/parkDynamics/${webName}`" :class="['ParkDynamics','ParkDynamicsDetail'].includes(pathName) ? 'active' :''">园区动态</router-link>
        <router-link :to="`/investmentPolicy/${webName}`" :class="['InvestmentPolicy','PolicyDetail'].includes(pathName) ? 'active' :''">招商政策</router-link>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'Heard',
  props: {
    title: {
      type: String,
      default: undefined,
    },
    needBack: {
      type: Boolean,
      default: false,
    },
	},
  data() {
    return {
			pathName:this.$route.name,
      showPopup: false,
      webName:this.$route.params.id
    };
  },
  activated() {
    this.closePopup()
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
  },
};
</script>

<style lang="less" scoped>
.left-title /deep/.van-nav-bar__title {
  margin: 0;
  padding-left: 14px;
 
}
.heard {
  background: #fff;
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  &::after{
    position: absolute;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    content: ' ';
    width: 100%;
    height: 2px;
    background: #eee;
    pointer-events: none;
    z-index: 1;
  }
  .van-hairline--bottom::after{
    display: none;
  }
  /deep/.van-nav-bar__content {
    height: 45px;
    .van-nav-bar__title {
      font-size: 18px;
      color: #333; 
      font-weight: bold;
    }
  }
  /deep/.van-nav-bar__left {
    padding: 0 10px;
  }
  /deep/.van-nav-bar__right {
    padding: 0 20px;
  }
  /deep/.van-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  .van-popup {
    width: 48%;
    height: 100%;
    background: #454955;
    overflow-y: inherit;
    .link {
      padding: 60px 0 0 15px;
      a {
        display: block;
        width: 100%;
        color: rgba(255, 255, 255, 0.6);
        text-align: left;
        font-size: 17px;
        margin-bottom: 25px;
      }
      .active {
        color: #fff;
      }
    }
    .colse-icon {
      position: absolute;
      right: 15px;
      top: 15px;
    }
	}
	.right-img{
		width: 22px;
		height: 17px;
	}
}
</style>
