<!--
 * @Author: WangHan
 * @Date: 2020-12-14 17:51:20
 * @LastEditors: WangHan
 * @LastEditTime: 2020-12-17 17:41:15
 * @Description: 首页轮播组件
-->
<template>
  <div class="swiper-box">
    <van-swipe
      class="my-swipe"
      :show-indicators="false"
      :loop="false"
      :width="swiperWidth"
    >
      <van-swipe-item
        v-for="item in list"
        :key="item.id"
        @click="toInfoDetail(item)"
      >
        <div class="swiper-item-box">
          <div
            :class="`bgc`"
            :style="{background: `url(${item.houseUrl}) center no-repeat`, backgroundSize:'cover'}"
          >
            <div
              class="info"
              :style="{width: infoWidth}"
            >
              <div class="title-box">
                <div class="tag">{{item.rentType === 1 ? '出租' : '出售'}}</div>
                <div class="title ellipsis">{{item.title}}</div>
              </div>
              <div class="price-box">
                <span class="price">{{item.priceText}}</span>
                <span class="area">{{item.houseAreaText}}</span>
              </div>
              <div class="house-tag-box">
                <div class="house-tag">{{item.specificTypeText}}</div>
              </div>
            </div>
          </div>

        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  name: 'SwiperInfo',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  created() {
    this.clientWidth = (document.documentElement.clientWidth || document.body.clientWidth)
  },
  computed: {
    swiperWidth() {
      return this.clientWidth - 45
    },
    infoWidth() {
      return this.swiperWidth - 45 + 'px'
    }
  },
  methods: {
    toInfoDetail(item) {
      this.$router.push({ name: 'InfoDetail', params: { id: this.$route.params.id },query:{
        id:item.id,
        houseType:item.houseType
      } })
    }
  },
}
</script>

<style lang="less" scoped>
@import "@/style/mixin/ellipsis.less";
.swiper-box {
  width: 100%;
  padding: 0 0 0 15px;
  box-sizing: border-box;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  height: 255px;
  text-align: center;
  background-color: #fff;
}
.swiper-item-box {
  width: 100%;
  padding-right: 15px;
  box-sizing: border-box;
}
.bgc {
  position: relative;
  width: 100%;
  height: 188px;
  border-radius: 3px;
}
.info {
  position: absolute;
  left: 0;
  bottom: -45px;
  background-color: #fff;
  height: 102px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 1px 9px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  box-sizing: border-box;
  padding: 16px 15px 15px;
  text-align: left;
  .title-box {
    display: flex;
    .tag {
      display: inline-block;
      // width: 28px;
      padding: 0 2px;
      height: 16px;
      line-height: 16px;
      font-size: 11px;
      box-sizing: border-box;
      color: #fff;
      background-color: #00bfd0;
      text-align: center;
      margin-top: 2.5px;
      border-radius: 2px;
    }
    .title {
      display: inline-block;
      margin-left: 4px;
      flex: 1;
      font-size: 15px;
      color: #333333;
      font-weight: bold;
    }
  }
  
  .price-box {
    margin-top: 5px;
    .price {
      font-size: 15px;
      color: #ff3b30;
      margin-right: 6px;
      font-weight: bold;
    }
    
    .area {
      font-size: 13px;
      color: #999999;
    }
  }
  .house-tag-box {
    margin-top: 3px;
    .house-tag {
      display: inline-block;
      //width: 56px;
      padding: 0 3px;
      height: 16px;
      line-height: 16px;
      border: thin solid #999999;
      border-radius: 2px;
      font-size: 12px;
      color: #999;
      text-align: center;
    }
  }
}
</style>