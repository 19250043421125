<!--
 * @Author: WangHan
 * @Date: 2020-12-10 13:37:36
 * @LastEditors: WangHan
 * @LastEditTime: 2020-12-18 14:18:59
 * @Description: 上拉加载/下拉刷新组件
-->
<template>
    <van-pull-refresh 
        v-model="refreshing" 
        @refresh="onRefresh"
        :style="`height: ${scrollHeight}px; overflow: scroll`" 
        ref="refresh" 
        v-bind="$attrs">
        <van-list
          v-model="loading"
          :finished="finished"
          @load="onLoad"
          v-bind="$attrs"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
        >
        <slot/>
        <div slot="finished"  v-if="list.length === 0">
          <NoData :style="`height: ${scrollHeight}px;`" :text="errText" :error="isError"/>
        </div>
      </van-list>
    </van-pull-refresh>
  
</template>

<script>
import {cloneDeep} from 'lodash'
import NoData from '@/components/NoData'
const codeMsg = {
  15: '该内容已下架，暂时无法浏览',
  99: '该内容已下架，暂时无法浏览',
  404: '该内容已下架，暂时无法浏览',
}
export default {
  name: 'ScrollList',
  components: {
    NoData
  },
  props: {
    // 请求配置
    requestConfig: {
      type: Object,
      required: true
    },
    list: {
      type: Array,
      default: () => []
    },
    handleResultData: {
      type: Function,
      default: r => r.data
    },
    scrollHeight: {
      type: [String, Number],
    }
  },
  data() {
    return {
      errText: '暂无数据',
      isError: false,
      error: false,
      loading: false,
      finished: false,
      refreshing: false,
      dynamicSlotName: [], // 动态插槽
      params: {
        pageNum: 1,
        pageSize: 5
      }
    };
  },
  mounted() {
    this.dynamicSlotName = Object.keys(this.$slots)
  },
  methods: {
    onLoad() {
        const refreshing = this.refreshing
        if (refreshing) {
          this.refreshing = false;
        }
        const requestConfig = this.requestConfig
        this.$request({
          ...requestConfig,
          data: {
            ...this.params,
            ...requestConfig.params,
            ...requestConfig.data,
          }
        }).then(res => {
          this.error = false
          const data = this.handleResultData(res)
          this.params.pageNum = this.params.pageNum + 1
          this.finished = data.pageNum * data.pageSize >= data.total
          this.changeListData(refreshing ? [...data.list] : [...cloneDeep(this.list), ...data.list])
        })
        .catch(err => {
          const code = err.data.code
          this.finished = true
          if(codeMsg[code]) {
            this.errText = codeMsg[code]
          } else {
            this.isError = true
          }
          console.log('err', err)
        })
        .finally(() => this.loading = false)

    },
    changeListData(newList) {
      this.$emit('update:list', newList)
    },
    //下拉刷新时会触发 refresh 事件，在事件的回调函数中可以进行同步或异步操作，操作完成后将 v-model 设置为 false，表示加载完成
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.params.pageNum = 1
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  },
}
</script>

<style lang="less" scoped>
</style>