<!--
 * @Author: yejincheng
 * @Date: 2020-12-15 10:59:02
 * @LastEditors: huangxingli
 * @LastEditTime: 2020-12-15 18:42:31
 * @Description: 
-->
<template>
  <div class="box-higth">
    <div class="empty">
      <img
        :src="error?errImg:nodata"
        alt=""
      >
      <p>{{error? '服务器异常':text}}</p>
			<div
				v-if="moreBut"
        class="moreBut"
        @click="butClick"
      >查看更多园区</div>
    </div>
  </div>

</template>

<script>
import errImg from '../../static/image/place_error.png'
import nodata from '../../static/image/ic_placeholder.jpg'
export default {
  name: 'NoData',
  props: {
    text: {
      type: String,
      default: '暂无数据'
		},
		moreBut: {
			type: Boolean,
      default: false
		},
		moreButText: {
			type: String,
      default: '查看更多'
		},
		error:{
			type:Boolean,
			default:false
		}
	},
	data(){
		return{
			errImg,
			nodata
		}
	},
	methods: {
		butClick() {
			this.$emit('butClick')
		}
	},
}
</script>

<style lang="less" scoped>
.empty {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  img {
    width: 130px;
    margin: auto;
    display: block;
  }
  width: 100%;
  p {
    text-align: center;
    font-size: 13px;
    color: #333;
  }
}
.box-higth{
	height: 100vh;
	background-color: #fff;
}
.moreBut {
    width: 135px;
    height: 36px;
    box-sizing: border-box;
    border: 1px solid #1677FF;
    color: #1677FF;
    text-align: center;
    line-height: 36px;
    border-radius: 3px;
    font-size: 13px;
    margin: 24px auto 0;
  }
</style>
