<!--
 * @Author: WangHan
 * @Date: 2020-12-10 17:18:07
 * @LastEditors: huangxingli
 * @LastEditTime: 2020-12-21 11:43:11
 * @Description: 
-->
<template>
  <div v-if="!noData && !error && !loading" class="container">
    <Heard class="fixedNav" :title="detail.officialName"></Heard>
    <div class="nav">
      <div @click="toIndex">首页</div>
      <div @click="toInvestmentInfo">招商信息</div>
      <div @click="toParkDynamics">园区动态</div>
      <div @click="toInvestmentPolicy">招商政策</div>
    </div>
    <div
      class="banner margin-b35"
      :style="{background: `url(${banner}) center no-repeat`, backgroundSize: 'cover'}"
    ></div>
    <div class="detail">
      <div class="title margin-b12">
        <div
          class="title-text"
          style="float:left"
        >园区概况</div>
        <div
          class="more"
          @click="toWebsiteDetail"
        >
          <span style="margin-right:4px">查看更多</span>
          <img
            width="8px"
            height="10px"
            src="../../../static/image/park_ic_skip.png"
          >
        </div>
      </div>
      <div class="detail-content">
        <div class="left"><img
            class="logo"
            width="120px"
            height="120px"
            :src="detail.showLogoUrl"
          ></div>
        <div class="right">
          <pre class="text">{{detail.description}}</pre>
          <div class="tag">
            <span v-for="(v,idx) in detail.positionLabels" :key="idx">{{v}}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="investmentInfoList.length>0" class="investmentInfo ">
      <div class="title-text margin-b12" style="padding:0 15px">招商信息</div>
      <div class="infoList">
        <SwiperInfo :list="investmentInfoList"></SwiperInfo>
      </div>
      <div style="padding:0 15px;margin-bottom:36px">
        <div
          v-if="showInfoMore"
          class="moreBox"
          @click="toInvestmentInfo"
        >查看更多</div>
      </div>
    </div>
    <div v-if="parkDynamicsList.length>0" class="parkDynamics margin-b35">
      <div class="title-text margin-b12">园区动态</div>
      <div class="contentList">
        <p
          v-for="(item,index) in parkDynamicsList"
          :key="index"
          class="ellipsis contentList-item"
          @click="toDynamicsDetail(item.id)"
        >{{item.title}}</p>
      </div>
      <div
        v-if="showDynamicsMore"
        class="moreBox"
        @click="toParkDynamics"
      >查看更多</div>
    </div>
    <div v-if="investmentPolicyList.length>0" class="investmentPolicy margin-b35">
      <div class="title-text margin-b12">招商政策</div>
      <div class="contentList">
        <p
          v-for="(item,index) in investmentPolicyList"
          :key="index"
          class="ellipsis contentList-item"
          @click="toPolicyDetail(item.id)"
        >{{item.title}}</p>
      </div>
      <div
        v-if="showPolicyMore"
        class="moreBox"
        @click="toInvestmentPolicy"
      >查看更多</div>
    </div>
    <SupportingTag
      v-if="detail.labels"
      class="supportingTag"
      :values="detail.labels"
    ></SupportingTag>
    <Amap
      v-if="detail.id"
      :address="get(detail,'province') + get(detail,'city') + get(detail,'area') + get(detail,'town') + detail.address"
      :marker="[detail.longitude,detail.latitude]"
    ></Amap>
    <Copyright class="footer"></Copyright>
  </div>
  <div v-else-if="loading"></div>
  <div v-else>
    <div class="noData">
      <NoData :error="error" style="height:65vh" moreBut moreButText="查看更多园区" text="该内容已下架，暂时无法浏览" @butClick="toZhaoshang800"></NoData>
    </div>
  </div>

</template>

<script>
import {get} from 'lodash';
import NoData from '@/components/NoData';
import Heard from '@/components/Heard';
import SwiperInfo from '@/components/SwiperInfo';
import SupportingTag from '@/components/SupportingTag';
import Amap from '@/components/Amap';
import Copyright from '@/components/Copyright';
import banner from '../../../static/image/home_page_h5.png';
import api from './services';
export default {
  name: 'Index',
  components: {
    NoData,
    Heard,
    SwiperInfo,
    SupportingTag,
    Amap,
    Copyright
  },
  data() {
    return {
      banner,
      loading: false,
      noData: false,
      detail: {},
      showPolicyMore: false,
      showInfoMore: false,
      showDynamicsMore: false,
      investmentInfoList: [],
      investmentPolicyList: [],
      parkDynamicsList: [],
      error:false
    }
  },
  created() {
    this.getDetailData()
    this.getInfoList()
    this.getPolicyList()
    this.getDynamicsList()
  },
  methods: {
    get,
    getDetailData() {
      this.loading = true
      this.$request({
        ...api.indexDetail
      }).then(res => {
        res.data.positionLabels = get(res,'data.positionLabels').split(',')
        this.detail = get(res,'data')
      }).catch(err=>{
        console.log('err', err)
        if([15,99,404].includes(err.data.code)) {
          this.noData = true
        }else{
          this.error=true
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    getPolicyList(){
      this.$request({
        ...api.investmentPolicy
      }).then(res => {
        this.showPolicyMore = false
        if (res.data.length > 4) {
          res.data.length = 4
          this.showPolicyMore = true
        }
        this.investmentPolicyList = res.data
      })
    },
    getInfoList(){
      this.$request({
        ...api.investmentInfo
      }).then(res => {
        this.showInfoMore = false
        if (res.data.length > 3) {
          res.data.length = 3
          this.showInfoMore = true
        }
        this.investmentInfoList = res.data
      })
    },
    getDynamicsList() {
      this.$request({
        ...api.parkDynamic
      }).then(res => {
        this.showDynamicsMore = false
        if (res.data.length > 4) {
          res.data.length = 4
          this.showDynamicsMore = true
        }
        this.parkDynamicsList = res.data
      })
    },
    // 刷新首页
    toIndex() {
      location.reload()
    },
    // 前往官网详情
    toWebsiteDetail() {
      this.$router.push({ name: 'ParkDetail', params: { id: this.$route.params.id } })
    },
    // 前往招商信息列表
    toInvestmentInfo() {
      console.log(this.$route.params.id);
      this.$router.push({ name: 'InvestmentInfo', params: { id: this.$route.params.id } })
    },
    // 前往动态列表
    toParkDynamics() {
      this.$router.push({ name: 'ParkDynamics', params: { id: this.$route.params.id } })
    },
    // 前往政策列表
    toInvestmentPolicy() {
      this.$router.push({ name: 'InvestmentPolicy', params: { id: this.$route.params.id } })
    },
    // 前往动态详情
    toDynamicsDetail(id) {
      this.$router.push({ name: 'ParkDynamicsDetail', params: { id: this.$route.params.id }, query: { id } })
    },
    // 前往政策详情
    toPolicyDetail(id) {
      this.$router.push({ name: 'PolicyDetail', params: { id: this.$route.params.id }, query: { id } })
    },
    // 前往中工招商网
    toZhaoshang800(id) {
      location.href = 'http://www.zhaoshang800.com/'
    },
  },
}
</script>

<style lang="less" scoped>
@import "@/style/mixin/ellipsis.less";
.container {
  padding-top: 45px;
}
.fixedNav {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.nav {
  height: 46px;
  display: flex;
  justify-content: space-around;
  font-size: 15px;
  font-weight: bold;
  div {
    line-height: 46px;
    padding: 0px 17px;
  }
}
.banner {
  width: 100%;
  height: 144px;
  background-size: cover;
}
.detail {
  padding: 0 15px;
  margin-bottom: 39px;
  .more {
    color: #1677ff;
    font-size: 12px;
    float: right;
    margin-top: 4px;
  }
  .title {
    height: 24px;
  }
  .detail-content {
    display: flex;
    .left,
    .right {
      height: 120px;
    }
    .left {
      width: 120px;
      border-radius: 3px;
      overflow: hidden;
      margin-right: 13px;
    }
    .right {
      flex: 1;
      .text {
        line-height: 1.7;
        margin: 0;
        font-size: 15px;
        white-space: pre-line;
        word-break: break-all;
        .multi-ellipsis(4);
      }
      .tag {
        height: 20px;
        overflow: hidden;
        margin-top: 2px;
        span{
          display: inline-block;
          border: 1px solid #999;
          border-radius: 2px;
          color: #999;
          font-size: 11px;
          margin-right: 9px;
          margin-bottom: 4px;
          padding: 0 5px;
        }
      }
    }
  }
}
.parkDynamics,
.investmentPolicy {
  padding: 0 15px;
  .contentList {
    padding-bottom: 4px;
  }
  .contentList-item {
    position: relative;
    font-size: 15px;
    color: #333333;
    line-height: 25px;
    margin: 0;
    margin-bottom: 13px;
    text-indent: 12px;
  }
  .contentList-item::after {
    content: "";
    display: block;
    position: absolute;
    top: 9px;
    left: 0;
    width: 6px;
    height: 6px;
    background-color: #1677ff;
  }
}
.supportingTag {
  margin-bottom: 35px;
}
.footer {
  margin-top: 22px;
}
.moreBox {
  line-height: 42px;
  border: 1px #1677ff solid;
  border-radius: 3px;
  text-align: center;
  color: #1677ff;
  font-size: 15px;
}
.margin-b35 {
  margin-bottom: 35px;
}
.margin-b12 {
  margin-bottom: 12px;
}
</style>