/*
 * @Author: yejincheng
 * @Date: 2020-12-10 16:56:39
 * @LastEditors: yejincheng
 * @LastEditTime: 2020-12-21 18:41:02
 * @Description: 
 */
import Vue from 'vue'
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import request from "./services/request";
import VueAMap from 'vue-amap'
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: window.location.host.startsWith('localhost') ? '999363cf917a7131ed95a9dfc0a837a2' : '72f65ca954e6c30a067d963d3cbd2de0',
  plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder'],
  v: '1.4.4',
  uiVersion: '1.0'
})
Vue.config.productionTip = false
Vue.use(Vant);
Vue.mixin({
  data() {
    return {
      CancelTokens: []
    }
  }
})
Vue.prototype.$request = request;
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
