<!--
 * @Author: WangHan
 * @Date: 2020-12-12 18:15:08
 * @LastEditors: WangHan
 * @LastEditTime: 2020-12-18 11:20:25
 * @Description: 
-->

<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <keep-alive include="InvestmentInfo">
        <router-view></router-view>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  watch: {
    $route(to, from) {
      this.statisticsOpenTimes();
    }
  },
  mounted() {
    this.statisticsOpenTimes();
  },
  methods:{
    // 统计官网打开次数
    statisticsOpenTimes(){
      this.$request({
        url:'/ipark-website/website/addCount',
        method: 'post',
        data: {
          type: 2,
          showLoading: false
        }
      })
    }
  }
}
</script>

<style lang="less">
@import "@/style/mixin/global.less";
</style>
