
import _ from 'lodash';
export default {
  //参数递归规范化
  paramsNormalize(Obj) {
    _.forOwn(Obj, (value, key, object) => {
      if (_.isString(value)) {
        object[key] = encodeURIComponent(_.trim(value));
      }

      if (_.isObject(value)) {
        this.paramsNormalize(value);
      }
    })
  },
  // 将详情中photoList的url组成单独的数组
  getPhotoList(photoList,key){
    if(!photoList) return []
    const arr=[]
    photoList.map(item=>{
      arr.push(item[key])
    })
    return arr
  },
  stringToTag(str){
    if(!str) return
    str = str.replace(/\n/g,"<br>");
    str = str.replace(/\s/g,"&nbsp;");
    return str
  }
}