<template>
  <div>
    <Heard :title="'园区概况'" :needBack="true" />
    <div v-if="!noData && !error">
      <div class="park-detail">
        <h3 class="park-name">{{ detailData.officialName }}</h3>
        <div class="park-lable">园区简介</div>
        <SwiperImg class="park-banner" :photoList="getPhotoList(detailData.photoList,'showUrl')" />
        <div class="des" v-html="stringToTag(detailData.description)"></div>
        <div class="park-lable">产业定位</div>
        <div class="positionLabels">
          <div v-for="(item, index) in detailData.positionLabels.split(',')" :key="index" class="tag">
            {{ item }}
          </div>
        </div>
        <div class="park-lable">园区规划</div>
        <div class="des" v-html="stringToTag(detailData.plan)"></div>
      </div>
      <Copyright class="footer"></Copyright>
    </div>
    <NoData v-else :error="error" :text="'该内容已下架，暂时无法浏览'" />
  </div>
</template>

<script>
import Heard from '@/components/Heard';
import SwiperImg from '@/components/SwiperImg';
import utils from '@/common/utils'
import Copyright from '@/components/Copyright'
import NoData from '@/components/NoData';
import { get } from 'lodash';
export default {
  name: 'InfoDetail',
  components: {
    SwiperImg,
		Heard,
    Copyright,
    NoData
	},
  data() {
    return {
      detailData: {
				photoList:undefined,
        positionLabels:'',
      },
      noData:false,
      error:false
    };
	},
	 created() {
    this.$request({
      method: 'post',
      url: '/ipark-website/website/detail',
    }).then(res=>{
      this.detailData = {...res.data}
    }).catch((err)=>{
      if([15,99,404].includes(err.data.code)) {
        this.noData = true
      }else{
        this.error=true
      }
    })
  },
  methods: {
		get,
		getPhotoList:utils.getPhotoList,
		stringToTag:utils.stringToTag,
    openContent() {
      this.showAll = !this.data.showAll;
		},
  },
};
</script>

<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}
.park-detail {
  padding: 0 15px 15px;
  margin-top: 69px;
}
.park-name {
  font-size: 27px;
  color: #333333;
  line-height: 25px;
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 23px;
  word-break: break-all;
}
.park-lable {
  font-size: 17px;
  color: #333333;
  font-weight: bold;
}
.park-banner {
  margin-top: 9px;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
}
.des {
  margin-top: 18px;
  margin-bottom: 30px;
  font-size: 15px;
	color: #333333;
  line-height: 25px;
  word-break: break-all;
}
.positionLabels {
	margin: 12px -15px 15px;
  .tag {
    display: inline-block;
    padding: 6px 22px;
    background: rgba(22, 119, 255, 0.1);
		border-radius: 6px;
		font-size: 15px;
		color: #1677FF;
		margin-left: 15px;
		margin-bottom: 15px;
  }
}
</style>
