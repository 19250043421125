<template>
  <div v-if="values.length > 0">
    <div class="title title-text">惠企配套</div>
    <div class="content">
      <van-grid :gutter="15" :border="false">
        <van-grid-item
          v-for="v in valueArr"
          :key="v"
          :text="v"
          class="grid-item"
        >
          <template slot="icon">
            <img class="imgIcon" :src="iconKey[v]">
          </template>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>

<script>
import way from '../../static/image/mating_ic_way.png'
import sewage from '../../static/image/mating_ic_sewage.png'
import gas from '../../static/image/mating_ic_gas .png'
import power from '../../static/image/mating_ic_power .png'
import heat from '../../static/image/mating_ic_heat.png'
import steam from '../../static/image/mating_ic_steam.png'
import communicate from '../../static/image/mating_ic_communicate.png'
import broadband from '../../static/image/mating_ic_broadband.png'
import tv from '../../static/image/mating_ic_tv.png'
import land from '../../static/image/mating_ic_land.png'
import plant from '../../static/image/mating_ic_plant.png'
import reduction from '../../static/image/mating_ic_reduction.png'

export default {
  name: "SupportingTag", // 惠企配套组件
  props: {
    values: {
      type: String,
      default: ''
    }
  },
  created() {
    this.valueArr = this.values.split(',')
  },
  data() {
    return {
      valueArr:[],
      iconKey: {
        '道路': way,
        '污水': sewage,
        '天然气': gas,
        '供电': power,
        '供热': heat,
        '蒸汽': steam,
        '通讯': communicate,
        '宽带网络': broadband,
        '有线电视': tv,
        '土地平整': land,
        '无尘车间': plant,
        '降噪': reduction,
      },
    }
  },
}
</script>

<style lang="less" scoped>
.title {
  margin-bottom: 16px;
  margin-left: 15px;
}
.imgIcon {
  width: 21px;
  height: 21px;
}
.grid-item {
  /deep/.van-grid-item__content {
    background-color: #f8f8f8;
    padding: 4px 8px 4px;
    border-radius: 3px;
  }
}
</style>