/*
 * @Author: yejincheng
 * @Date: 2020-12-12 17:32:51
 * @LastEditors: yejincheng
 * @LastEditTime: 2020-12-14 17:32:51
 * @Description: 
 */
const api = {
  indexDetail:{
    url:'/ipark-website/website/detail',
    method:'post'
  },
  parkDynamic:{
    url:'/ipark-website/websiteiparkdynamic/h5/dynamic',
    method:'post'
  },
  investmentPolicy:{
    url:'/ipark-website/h5/home/policy',
    method:'get'
  },
  investmentInfo:{
    url:'/ipark-website/h5/home/info',
    method:'get'
  },
}
export default api