<template>
  <div class="container">
    <Heard title="招商信息"/>
    <div class="tab-bar">
      <van-tabs v-model="active" style="width: 100%;" animated swipeable sticky>
        <van-tab v-for="(item, index) in tabBar" :key="index" :title="item.title" title-active-color="#333">
          <div class="list">
            <ScrollList :requestConfig="requestConfig[item.type]" :list.sync="list[item.type]" :scrollHeight="scrollHeight">
              <div class="list-item" v-for="(data, index) in list[item.type]" :key="index" @click="toInfoDetail(data.id)">
                <div class="lunbo">
                  <van-image
                    fit="cover"
                    :src="data.houseUrl"
                  />
                </div>
                <div class="bottom">
                  <div class="title-box">
                    <div class="tag">{{data.rentType === 1 ? '出租' : '出售'}}</div>
                    <div class="title">{{data.title}}</div>
                  </div>
                  <div class="price-box">
                    <span class="price">{{data.priceText}}</span>
                    <span class="area">{{data.houseAreaText}}</span>
                  </div>
                  <div class="house-tag-box">
                    <div class="house-tag">{{data.specificTypeText}}</div>
                  </div>
                </div>
              </div>
            </ScrollList>
          </div>
        </van-tab>
      </van-tabs>

    </div>
  </div>
</template>

<script>
import ScrollList from '@/components/ScrollList'
import Heard from '@/components/Heard'
const listConfig = { url: '/ipark-website/h5/info/page', method: 'post' }
export default {
  name: 'InvestmentInfo',
  components: {
    ScrollList,
    Heard
  },
  computed: {
    scrollHeight() {
      return (document.documentElement.clientHeight || document.body.clientHeight) - 52 - 44
    }
  },
  data() {
    return {
      active: 0,
      tabBar: [
        {title: '厂房招商', type: 'plant'}, 
        {title:'仓库招商', type:'store'}, 
        {title: '写字楼招商', type: 'office'}
      ],
      list: {
        plant: [],
        store: [],
        office: []
      },
      requestConfig: {
        plant: {
          ...listConfig,
          data: {
            houseType: 1
          }
        },
        store: {
          ...listConfig,
          data: {
            houseType: 2
          }
        },
        office: {
          ...listConfig,
          data: {
            houseType: 3
          }
        },
        
      }
    }
  },
  watch: {
    active(val) {
      this.requestConfig.data = val + 1
      this.$route.meta.index = val
    }
  },
  activated () {
    const scrollTop = this.$route.meta.scrollTop
    const $content = document.querySelectorAll('.van-pull-refresh')
    for(let i = 0; i < $content.length; i++) {
      $content[i].scrollTop = scrollTop[i];
    }
    
  },
  methods: {
    toInfoDetail(id) {
      this.$router.push({ name: 'InfoDetail', params: { id: this.$route.params.id }, query: { id, houseType: this.active + 1 } })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-tabs__wrap {
  height: 52px;
  .van-tabs__nav {
    padding-bottom: 0;
    .van-tab__text  {
      font-size: 15px;
      color: #999;
    }
    .van-tab--active .van-tab__text {
      color: #333;
      font-weight: bold;
    }
  }
  .van-tabs__line {
    width: 24px;
    height: 4px;
    background-color: #1677FF;
    border-radius: 0;
    bottom: 0;
  }
}

.container {
  // height: 100%;
  background-color: #f8f8f8;
  // overflow: hidden;
  margin: 45px 0 0;
  box-sizing: border-box;
}
.tab-bar {
  display: flex;
  height: 52px;
  background-color: #fff;
  // .tab-bar-item {
  //   position: relative;
  //   flex: 1;
  //   color: #333;
  //   font-size: 15px;
  //   text-align: center;
  //   line-height: 52px;
  //   font-weight: bold;
  // }
  // .ele::after {
  //     position: absolute;
  //     bottom: 0;
  //     left: 50%;
  //     transform: translateX(-50%);
  //     content: '';
  //     width: 24px;
  //     height: 4px;
  //     background-color: #1677FF;
  //   }
}
.list {
  // height: calc(100vh - 52px - 44px);
  background: #f8f8f8;
  .list-item {
    padding: 14px 15px 0;
    .lunbo {
      height: 166px;
      background-color: #fff;
      border-radius: 3px;
      overflow: hidden;
    }
    .bottom {
      background-color: #fff;
      padding: 9px 15px 13.5px;
      text-align: left;
      .title-box {
        display: flex;
        .tag {
          display: inline-block;
          // width: 28px;
          padding: 0 2px;
          height: 15px;
          line-height: 15px;
          font-size: 11px;
          color: #fff;
          background-color: #00BFD0;
          text-align: center;
          margin-top: 2.5px;
          border-radius: 2px;
        }
        .title {
          display: inline-block;
          margin-left: 4px;
          flex: 1;
          font-size: 15px;
          color: #333333;
          font-weight: bold;
        }
      }
      .price-box {
        margin-top: 5px;
        .price {
          font-size: 15px;
          color: #FF3B30;
          margin-right: 6px;
          font-weight: bold;
        }
        .area {
          font-size: 13px;
          color: #999999;
        }
      }
      .house-tag-box {
        margin-top: 3px;
        .house-tag {
          display: inline-block;
          padding: 0 3px;
          // width: 56px;
          height: 16px;
          line-height: 16px;
          align-items: center;
          border: thin solid #999999;
          border-radius: 2px;
          font-size: 11px;
          color: #999;
          text-align: center;
        }
      }
    }
  }
}
</style>