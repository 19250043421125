<template>
  <div class="copyright">
    <p><span>粤ICP备</span><span style="margin-left:8px">15009394号-1</span></p>
    <p><span>深圳市伙伴行网络科技有限公司 </span><span style="margin-left:6px">版权所属</span></p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.copyright {
  height: 78px;
  box-sizing: border-box;
  padding: 18px;
  text-align: center;
  background-color: #454955;
  font-size: 13px;
  font-family: PingFangSC-Regular;
  color: #ffffff;
  p {
    margin: 0;
    margin-bottom: 5px;
    opacity: 0.5;
    span {
      line-height: 19px;
    }
  }
}
</style>