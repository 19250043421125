<!--
 * @Author: yejincheng
 * @Date: 2020-12-11 18:08:20
 * @LastEditors: yejincheng
 * @LastEditTime: 2020-12-17 18:30:38
 * @Description: 
-->
<template>
  <div class="mapPlate">
    <div class="title title-text">{{title}}</div>
    <div class="address">{{address}}</div>
    <el-amap
      class="AMap"
      ref="map"
      :amap-manager="amapManager"
      :zoom="zoom"
      :center="marker"
      vid="amapDemo"
      :dragEnable="false"
    >
      <el-amap-marker :position="marker" :icon="location"></el-amap-marker>
    </el-amap>
  </div>
</template>

<script>
import { AMapManager } from 'vue-amap'
import location from '../../static/image/park_location.png';
const amapManager = new AMapManager()
export default {
  name: 'Amap',
  props: {
    title: {
      type: String,
      default: '园区位置'
    },
    address: {
      type: String,
      default: ''
    },
    marker:{
      type:Array,
      default:()=>[116.397451, 39.909187]
    }
  },
  data() {
    return {
      location,
      amapManager,
      zoom: 16, // 缩放比例
    }
  },
}
</script>

<style lang="less" scoped>
.mapPlate {
  /deep/.AMap {
    height: 186px;
    .amap-logo {
      display: none !important;
    }
    .amap-copyright {
      display: none !important;
    }
  }
  .title {
    margin-bottom: 9px;
    margin-left: 15px;
  }
  .address {
    font-size: 15px;
    color: #333333;
    line-height: 22px;
    margin: 0 15px;
    margin-bottom: 13px;
  }
}
</style>