<!--
 * @Author: WangHan
 * @Date: 2020-12-10 17:20:17
 * @LastEditors: WangHan
 * @LastEditTime: 2020-12-17 10:44:50
 * @Description: 
-->
<template>
  <div class="content">
    <Heard title="园区动态"/>
    <ScrollList 
      :list.sync="list" 
      :requestConfig="requestConfig" 
      :handleResultData="res => res.data.pageQuery" 
      :scrollHeight="scrollHeight"> 
      <template v-slot:default>
        <section class="content-item" v-for="(item,index) in list" :key="index" @click="toDynamicsDetail(item.id)">
          <div class="content-item-box">
            <div class="content-item-title">{{item.title}}</div>
            <div class="content-item-desc">{{item.content}}</div>
            <span class="content-item-date">{{item.updateDate}}</span>
          </div>
        </section>
      </template>
    </ScrollList>
  </div>
</template>
<script>
import ScrollList from '@/components/ScrollList'
import Heard from '@/components/Heard'
export default {
  name:'ParkDynamics',
  components: {
    ScrollList,
    Heard
  },
  data(){
    return {
      list:[],
      requestConfig: {
        method: 'post',
        url: '/ipark-website/websiteiparkdynamic/h5/dynamicPageList',
      }
    }
  },
  computed: {
    scrollHeight() {
      return (document.documentElement.clientHeight || document.body.clientHeight) - 54
    }
  },
  methods:{
    // 前往动态详情
    toDynamicsDetail(id) {
      this.$router.push({ name: 'ParkDynamicsDetail', params: { id: this.$route.params.id }, query: { id } })
    },
    // requestMethod(params) {
    //   return Promise.resolve({data: [
    //     {title: '马云马化腾对决交通场景，一文说清双方布局', 
    //     desc: '左手交易手续费，右手项目上币费，在疯狂增长的数字货币市场，交易所毋容置疑是最大赢家233333333333333333334',
    //     date: '2017-11-08'},
    //     ]})
    // }
  }
}
</script>
<style lang="less" scoped>
@import "@/style/mixin/ellipsis.less";
@import "@/style/mixin/list.less";
</style>
